<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
        <div class="app-modal__in">
            <div
                class="app-modal__header d-flex f-between p-5 m-title-modal"
            >
                <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                    {{
                        $t("message.edit_m", {
                            m: $t("message.type_expense"),
                        })
                    }}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <!-- app-modal__header end -->

        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4 type-expensies" :class="mode ? 'table__myday' : 'table__mynight'">
              <el-form
              ref="form"
              status-icon
              :model="form"
              :rules="rules"
             
              >
              <el-row :gutter="20">
                  <el-col :span="24" >
                          <el-form-item
                          :class="mode ? 'input__day' : 'input__night'"
                            :label="$t('message.name')"
                            prop="name"
                            class="label_mini">
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.name"
                                v-model="form.name"
                                :placeholder="$t('message.name')"
                            ></crm-input>
                            </el-form-item>
                            <el-form-item
                            :class="mode ? 'input__day' : 'input__night'" prop="paid_amount">
                              <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                <span class="start_top">*</span>
                                {{ $t("message.amount") }}
                              </span>
                              <crm-input
                                :inputValue="form.money_amount"
                                :placeholder="$t('message.amount')"
                                v-model="form.money_amount"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                            </el-form-item>
                            <el-form-item
                            :class="mode ? 'input__day' : 'input__night'" prop="currency_id">
                              <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                <span class="start_top">*</span>
                                {{ $t("message.currency") }}
                              </span>
                              <select-currency
                                :id="form.currency_id"
                                v-model="form.currency_id"
                              ></select-currency>
                            </el-form-item> 
                            <el-form-item
                            :class="mode ? 'input__day' : 'input__night'"
                              :label="$t('message.comment')"
                              prop="comment"
                              class="label_mini">
                              <el-input
                                  type="textarea"
                                  :rows="1"
                                  :placeholder="$t('message.comment')"
                                  :inputValue="form.comment"
                                  v-model="form.comment"
                                  class="text-area-el"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          
                          <!-- end col -->
                        </el-row>
                      </el-form>
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "RegionController",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "typeExpense/rules",
            model: "typeExpense/model",
            columns: "typeExpense/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "typeExpense/update",
            show: "typeExpense/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
